:root {
    --color-presentation: rgb(224, 170, 25);
    --color-etudes: rgb(19, 31, 17);
    --color-experiences_professionnelles: rgb(2, 62, 24);
    --color-centres_d_interets: rgb(241, 107, 36);
    --color-competences: rgb(124, 86, 1);
    --color-experiences_additionnelles: rgb(250, 213, 207);
    --color-book: rgb(0, 111, 83);
    --color-contact: rgb(246, 178, 159);
}

@font-face {
    font-family: 'Hearth_Stone';
    src: url("./fonts/Hearth_Stone.ttf") format("truetype"), url("./fonts/Hearth_Stone.otf") format("opentype");
}

@font-face {
    font-family: 'Futurist';
    src: url("./fonts/Futurist.ttf") format("truetype");
}

@font-face {
    font-family: 'Microsoft';
    src: url("./fonts/Microsoft.ttf") format("truetype");
}

@font-face {
    font-family: 'Palatino';
    src: url("./fonts/Palatino.ttf") format("truetype");
}


/* Page */

.bodyPage {
    margin-top: 7vh;
    min-height: 93vh;
}


/* App */

.bodyPhoto {
    display: none;
}

@media screen and (min-width: 768px) {
    .bodyPhoto {
        padding-top: 7vh;
        width: 100vw;
        height: 93vh;
        position: absolute;
        display: flex;
    }
}

@media screen and (min-width: 768px) {
    .photo {
        margin: auto;
        z-index: 10;
    }
    .rounded-circle {
        width: 200px;
    }
}

.app {
    margin-top: 7vh;
    height: 93vh;
    width: 100vw;
    background-color: rgb(2, 34, 102);
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: white;
}

@media screen and (min-width: 768px) {
    .app {
        display: block;
    }
}

.ligne {
    display: flex;
    height: calc(100% / 4);
}

@media screen and (min-width: 768px) {
    .ligne {
        display: block;
    }
}

.case {
    display: flex;
    width: 50%;
    color: white;
}

.case:hover {
    color: rgb(2, 34, 102);
    text-decoration: none;
    background-color: rgb(89, 13, 43);
    border-radius: 5px;
}

@media screen and (min-width: 768px) {
    .case {
        display: block;
        position: absolute;
    }
}

.presentation {
    background-color: var(--color-presentation);
}

@media screen and (min-width: 768px) {
    .presentation {
        top: 7vh;
        left: -50vw;
        width: 100vw;
        height: 47vh;
        clip-path: polygon(50% 0, 100% 0, 100% 100%);
        z-index: 5;
    }
    .titre_presentation {
        position: fixed;
        top: 20%;
        left: 30%;
    }
}

.etudes {
    background-color: var(--color-etudes);
}

@media screen and (min-width: 768px) {
    .etudes {
        width: 100vw;
        height: 47vh;
        clip-path: polygon(100% 0, 0 0, 50% 100%);
        z-index: 2;
    }
    .titre_etudes {
        position: fixed;
        top: 20%;
        left: 60%;
    }
}

.experiences_professionnelles {
    background-color: var(--color-experiences_professionnelles);
}

@media screen and (min-width: 768px) {
    .experiences_professionnelles {
        top: 8vh;
        left: 50vw;
        width: 50vw;
        height: 46vh;
    }
    .titre_experiences_professionnelles {
        position: fixed;
        top: 32%;
        left: 72%;
    }
}

@media screen and (min-width: 1025px) {
    .titre_experiences_professionnelles {
        left: 75%;
    }
}

.centres_d_interets {
    background-color: var(--color-centres_d_interets);
}

@media screen and (min-width: 768px) {
    .centres_d_interets {
        top: 54vh;
        left: 50vw;
        width: 50vw;
        height: 46vh;
    }
    .titre_centres_d_interets {
        position: fixed;
        top: 60%;
        left: 75%;
    }
}

.competences {
    background-color: var(--color-competences);
}

@media screen and (min-width: 768px) {
    .competences {
        top: 54vh;
        width: 100vw;
        height: 46vh;
        clip-path: polygon(50% 0, 50% 100%, 100% 100%);
        z-index: 2;
    }
    .titre_competences {
        position: fixed;
        top: 85%;
        left: 60%;
    }
}

.experiences_additionnelles {
    background-color: var(--color-experiences_additionnelles);
}

@media screen and (min-width: 768px) {
    .experiences_additionnelles {
        top: 54vh;
        left: -50vw;
        width: 100vw;
        height: 46vh;
        clip-path: polygon(100% 0, 50% 100%, 100% 100%);
        z-index: 2;
    }
    .titre_experiences_additionnelles {
        position: fixed;
        top: 85%;
        left: 15%;
    }
}

@media screen and (min-width: 1025px) {
    .titre_experiences_additionnelles {
        left: 25%;
    }
}

.book {
    background-color: var(--color-book);
}

@media screen and (min-width: 768px) {
    .book {
        top: 54vh;
        width: 50vw;
        height: 46vh;
    }
    .titre_book {
        position: fixed;
        top: 70%;
        left: 10%;
    }
}

.contact {
    background-color: var(--color-contact);
}

@media screen and (min-width: 768px) {
    .contact {
        top: 7vh;
        left: -50vw;
        width: 100vw;
        height: 47vh;
        clip-path: polygon(50% 0, 50% 100%, 100% 100%);
    }
    .titre_contact {
        position: fixed;
        top: 35%;
        left: 10%;
    }
}

.domaine {
    margin: auto;
}

.titre_domaine {
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    font-family: 'Palatino', sans-serif;
    font-style: italic;
}

@media screen and (min-width: 768px) {
    .titre_domaine {
        font-size: 1.3rem;
    }
}


/* A propos */

.bodyAPropos {
    background-color: #01032d;
    min-height: 81vh;
    color: white;
    display: flex;
}

.lienAPropos {
    margin: auto;
    color: white;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .lienAPropos {
        font-size: 2rem;
    }
}

.url {
    color: white;
}

.url:hover {
    color: yellow;
    text-decoration: none;
}


/* Book */

.bodyBook {
    background-color: var(--color-book);
    min-height: 81vh;
    padding-bottom: 5%;
}

.divCarte {
    min-height: 30vh;
    margin: 4% 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .divCarte {
        min-height: 30vh;
    }
}

@media screen and (min-width: 1025px) {
    .divCarte {
        min-height: 30vh;
        margin-bottom: 0;
    }
}

.carte {
    margin: 2%;
    background-color: white;
    border-radius: 5px;
    height: 100%;
    box-shadow: 10px 5px 5px black;
}

.couvCarte {
    height: 100%;
    width: 90%;
    position: absolute;
    display: flex;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .couvCarte {
        width: 86%;
    }
}

@media screen and (min-width: 1025px) {
    .couvCarte {
        width: 91%;
    }
}

.aRond {
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white !important;
    display: flex !important;
    border: solid 1px black;
}

.lienCarte {
    margin: auto;
}

.up {
    height: 50%;
    width: 100%;
    z-index: 1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.upCarteDeVisite {
    background-color: var(--color-presentation);
}

.upFairePart {
    background-color: var(--color-etudes);
}

.upAffiche {
    background-color: var(--color-centres_d_interets);
}

.upDossierClients {
    background-color: var(--color-competences);
}

.upAutre {
    background-color: var(--color-contact);
}

.down {
    min-height: 50%;
    width: 100%;
    background-color: white;
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: solid 1px black;
}

.titreDown {
    padding-top: 22%;
    text-align: center;
    font-family: 'Palatino', sans-serif;
    font-style: italic;
}

@media screen and (min-width: 540px) and (max-width: 767px) {
    .titreDown {
        padding-top: 13%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .titreDown {
        padding-top: 35%;
    }
}

@media screen and (min-width: 1025px) {
    .titreDown {
        padding-top: 20%;
    }
}

.sixieme {
    display: none;
}


/* Carte de visite */

.bodyBookDetails {
    background-color: var(--color-book);
    min-height: 81vh;
    padding-top: 2%;
    padding-bottom: 5%;
}

.retourBook {
    text-align: center;
}

.cardDetails {
    width: 80%;
    min-height: 30vh;
    margin: auto;
    box-shadow: 10px 5px 5px black;
    margin-top: 5%;
}

@media screen and (min-width: 768px) {
    .cardDetails {
        margin-top: 2%;
    }
}

.upDetails {
    height: 100px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
}

.upDetailsCarte {
    background-color: var(--color-presentation);
}

.upDetailsFairepart {
    background-color: var(--color-etudes);
}

.upDetailsAffiche {
    background-color: var(--color-centres_d_interets);
}

.upDetailsDossierClients {
    background-color: var(--color-competences);
}

.upDetailsAffiche {
    background-color: var(--color-contact);
}

.titreCardDetails {
    margin: auto;
}

.titreCardDetailsFairepart {
    color: white;
    margin: auto;
}

.downDetails {
    min-height: 35vh;
    background-color: white;
    padding: 2% 0 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    border: white solid;
}

@media screen and (min-width: 768px) {
    .downDetails {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.divImgHorizontale {
    text-align: center;
    padding: 4%;
}

.imgHorizontale {
    width: 180px;
    border: solid 1px black;
    border-radius: 5px;
    box-shadow: 10px 5px 5px black;
}

@media screen and (min-width: 768px) {
    .downDetails {
        flex-direction: row;
    }
    .divImgHorizontale {
        padding: 2%;
    }
    .imgHorizontale {
        width: 260px;
    }
}

.imgVerticale {
    width: 180px;
    border: solid 1px black;
    border-radius: 5px;
    box-shadow: 10px 5px 5px black;
}

@media screen and (min-width: 768px) {
    .downDetails {
        flex-direction: row;
    }
    .divImgHorizontale {
        padding: 2% 5% 2% 5%;
    }
    .imgVerticale {
        width: 250px;
    }
}


/* Centres d'interets */

.bodyCentresDInterets {
    background-color: var(--color-centres_d_interets);
    min-height: 81vh;
    padding: 5%;
}

.centre {
    width: 25vw;
    height: 30vh;
}

.divHexagone {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.hexagone {
    margin: auto;
    position: relative;
    /* Pour rendre responsive mettre width à 100% */
    width: 130px;
    visibility: hidden;
    overflow: hidden;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.6));
    transform: rotate(90deg);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .hexagone {
        width: 150px;
    }
}

.hexagone:after {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    display: block;
    margin-top: 28.86751%;
    padding-bottom: 86.602%;
}

.hexagone .hexagonemain {
    display: block;
    position: absolute;
    width: 100%;
    padding-bottom: 115.47005%;
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    z-index: 1;
}

.hexagone .hexagonemainbefore {
    content: "";
    display: block;
    position: absolute;
    visibility: visible;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
    -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
    transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

.danse {
    background-image: url("img/danse.jpg");
}

.montage {
    background-image: url("img/montage.jpg");
}

.voyages {
    background-image: url("img/voyages.jpg");
}

.musique {
    background-image: url("img/musique.jpg");
}

.bullet {
    background-image: url("img/bullet.jpg");
}

.hexagone .hexagonecontent {
    overflow-y: auto;
    width: 100%;
    display: block;
    position: absolute;
    visibility: visible;
    bottom: 25%;
    top: 25%;
    left: 0;
    z-index: 3;
}

.hexagone img {
    left: -100%;
    right: -100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
    -webkit-transform: rotate3d(0, 0, 0, 0deg);
    -ms-transform: rotate3d(0, 0, 0, 0deg);
    transform: rotate3d(0, 0, 0, 0deg);
}

.titreCentre {
    margin: auto;
    font-family: 'Palatino', sans-serif;
    font-style: italic;
}


/* Competences */

.detailsCompetences {
    background-color: var(--color-competences);
    min-height: 81vh;
    padding-top: 2%;
}

@media screen and (min-width: 1025px) {
    .detailsCompetences {
        padding-top: 0;
    }
}

.bloc {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .bloc {
        flex-direction: row;
    }
}

.sous-bloc {
    width: 100%;
    min-height: 30vh;
    padding-top: 2%;
}

@media screen and (min-width: 768px) {
    .sous-bloc {
        padding-left: 2%;
    }
}

@media screen and (min-width: 1024px) {
    .sous-bloc {
        padding-left: 5%;
    }
}

.titreDomaine {
    margin-left: 5%;
    color: white;
    font-family: 'Futurist', sans-serif;
    padding-bottom: 2%;
    font-weight: bold;
}

.liSousBloc {
    list-style: none;
    min-height: 8vh;
}

.domaine {
    display: inline-block;
    line-height: 8vh;
    width: 100px;
    color: white;
}

.logo {
    width: 50px;
    border: solid 1px white;
    margin-right: 30px;
}

.logoOffice {
    width: 50px;
    border: solid 1px white;
    margin-right: 50px;
}

.logoMontage {
    width: 50px;
    border: solid 1px white;
    margin-right: 50px;
}

.spanStar {
    line-height: 5vh;
    margin-right: 10px;
}

.star {
    width: 20px;
    line-height: 15vh;
}

.jaune {
    filter: hue-rotate(180deg);
}

.domaineAutres {
    color: white;
    width: 100%;
    font-size: 1em;
    padding-top: 2%;
}

.liSousBlocAutres {
    list-style: none;
}

.sous-blocAutres {
    width: 100%;
    min-height: 30vh;
    padding-top: 2%;
}

@media screen and (min-width: 1024px) {
    .sous-blocAutres {
        padding-left: 5%;
    }
}


/* Contact */

.detailsContact {
    background-color: var(--color-contact);
    min-height: 81vh;
}

@media screen and (min-width: 768px) {
    .detailsContact {
        min-height: 78vh;
    }
}

@media screen and (min-width: 1300px) {
    .detailsContact {
        min-height: 75vh;
    }
}

.sous-titre {
    font-family: 'Microsoft', sans-serif;
    font-size: 2em;
    font-weight: bold;
}


/* En_tete */

.nomPrenom {
    min-height: 12vh;
    text-align: center;
    margin: 0;
    display: flex;
}

.titreEnTete {
    margin: auto;
}

.titre_nomPrenom {
    font-family: 'Hearth_Stone', sans-serif;
    font-size: 1.2em;
    padding: 4% 0 4% 0;
    border: solid 1px;
    border-top: unset;
    border-right: unset;
    border-left: unset;
}

@media screen and (min-width: 768px) {
    .titre_nomPrenom {
        font-size: 2.2em;
        padding-top: 7%;
    }
}

.intitule {
    font-family: 'Futurist', sans-serif;
    font-size: 8px;
    padding-top: 0;
}

@media screen and (min-width: 768px) {
    .intitule {
        font-size: 15px;
    }
}

@media screen and (min-width: 1300px) {
    .intitule {
        padding-top: 0;
        font-size: 1em;
    }
}


/* Etudes */

.detailsEtudes {
    background-color: var(--color-etudes);
    min-height: 81vh;
    color: white;
}

.etude {
    display: flex;
    flex-direction: column;
    min-height: 20vh;
    border-bottom: solid 1px white;
}

@media screen and (min-width: 768px) {
    .etude {
        flex-direction: row;
    }
}

.typeEtude {
    display: flex;
    flex-direction: column;
    min-height: 10vh;
}

@media screen and (min-width: 768px) {
    .typeEtude {
        width: 20vw;
        margin: auto;
    }
    .descEtude {
        width: 80vw;
    }
}

.typeEtude_nom {
    display: flex;
    width: 100px;
    height: 50px;
    border: solid 1px white;
    margin: 0 auto;
    margin-top: 2%;
    margin-bottom: 2%;
}

.typeEtude_nom_vertical {
    display: flex;
    width: 70px;
    height: 100px;
    border: solid 1px white;
    margin: 0 auto;
    margin-top: 2%;
    margin-bottom: 2%;
}

.typeEtude_nom_h5 {
    margin: auto;
}

.logoEcole {
    width: 100%;
    height: 100%;
}

.typeEtude_nom_p {
    text-align: center;
}

.descEtude {
    display: flex;
    flex-direction: column;
    min-height: 10vh;
    padding: 2%;
}

.descEtude_nom_h5 {
    text-align: center;
    font-family: 'Futurist', sans-serif;
    font-weight: bold;
}

.descEtude_nom_p {
    text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .typeEtude_nom_h5 {
        font-size: 1.5rem;
    }
    .typeEtude_nom_p {
        font-size: 1.5rem;
    }
    .descEtude_nom_h5 {
        font-size: 1.5rem;
    }
    .descEtude_nom_p {
        font-size: 1.5rem;
    }
}


/* Experiences additionnelles */

.detailsExp_add {
    background-color: var(--color-experiences_additionnelles);
    min-height: 81vh;
}

.exp_add {
    min-height: 20vh;
    border-bottom: solid 1px white;
    padding-top: 2%;
}

.titreExpAdd {
    background-color: white;
    text-align: center;
    margin: 0 5%;
    font-family: 'Microsoft', sans-serif;
    font-weight: bold;
    font-size: 2em;
}

.duree {
    text-align: center;
    padding-top: 2%;
    font-weight: bold;
}

.detailsExpAdd {
    text-align: justify;
    padding: 2% 5%;
}


/* Experiences professionnelles */

.detailsExp_pro {
    background-color: var(--color-experiences_professionnelles);
    min-height: 81vh;
    width: 100%;
    padding: 5% 0 5% 0;
}

@media screen and (min-width: 768px) {
    .detailsExp_pro {
        min-height: 78vh;
    }
}

@media screen and (min-width: 1300px) {
    .detailsExp_pro {
        min-height: 75vh;
    }
}

@media screen and (min-width: 768px) {
    .vertical-timeline-element--work {
        color: white;
    }
}

@media screen and (max-width: 319px) {
    .vertical-timeline-element-title {
        font-size: 0.8rem;
    }
}

.titres {
    color: black;
}

.titreLong {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .titreLong {
        flex-direction: row;
    }
}

.titreLong2 {
    margin-left: 1%;
}

@media screen and (max-width: 319px) {
    .titreLong2 {
        font-size: 0.8rem;
    }
}

.fontWeightBold {
    font-weight: bold;
    font-family: 'Microsoft', sans-serif;
}

.whiteSpaceNormal {
    white-space: normal;
    padding-top: 5%;
    color: grey;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .whiteSpaceNormal {
        font-size: 1.5em;
    }
}

.bBlack {
    font-weight: bold;
    color: black;
    font-style: italic;
}


/* Header */

.navbar {
    height: 7vh;
    width: 100vw;
    z-index: 10000 !important;
}

.backgroundNavbar {
    background-image: url("img/fond_50.jpg");
    background-size: cover;
    background-position: 25% 75%;
}

.nav-link {
    background-color: white !important;
    z-index: 100 !important;
    color: rgb(89, 13, 43) !important;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .nav-link {
        background-color: rgba(255, 255, 255, 0);
    }
}

@media screen and (min-width: 1024px) and (max-width: 1030px) {
    .nav-link {
        height: 5vh;
    }
}

.activeAccueil {
    background-color: transparent !important;
    border: transparent !important;
}

.active {
    border: solid white;
    background-color: white;
    height: 7vh;
}


/* Not Found */

.bodyNotFound {
    background-color: black;
    min-height: 81vh;
    color: white;
    display: flex;
}

.messageNotFound {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.gif {
    width: 200px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .gif {
        width: 300px;
    }
}

@media screen and (min-width: 1025px) {
    .gif {
        width: 400px;
    }
}

.retourAccueil {
    color: white;
    padding-top: 5%;
    text-align: center;
}

.retourAccueil:hover {
    text-decoration: none;
    color: yellow;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .retourAccueil {
        font-size: 2rem;
    }
}


/* About me */

.detailsPresentation {
    background-color: var(--color-presentation);
    min-height: 81vh;
}

.detailsPresentation_container {
    display: flex;
    flex-direction: column;
    min-height: 61vh;
}

@media screen and (min-width: 768px) {
    .detailsPresentation_container {
        flex-direction: row;
    }
}

.photoMarine {
    display: flex;
    height: 50vh;
}

.imgPhotoMarine {
    width: 200px;
    margin: auto;
    padding: 2%;
}

.description {
    text-align: justify;
    display: flex;
}

@media screen and (min-width: 768px) {
    .photoMarine {
        width: 40vw;
    }
    .imgPhotoMarine {
        width: 300px;
    }
    .description {
        width: 60vw;
    }
}

.titreDescription {
    text-align: center;
    padding-top: 2%;
    font-family: 'Hearth_Stone', sans-serif;
    font-size: 2rem;
}

.texteDescription {
    margin: auto;
    padding: 3%;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
    .texteDescription {
        font-size: 1.2em;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1024px) {
    .texteDescription {
        font-size: 1.5em;
    }
}

.buttonCV {
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Fira Sans Condensed', sans-serif;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    margin-top: 20px;
    padding: 18px 50px;
    border-radius: 3px;
    transition: all 0.3s ease-in 0s;
    display: inline-block;
    margin-bottom: 5%;
}

.buttonCV:hover {
    background: white;
    color: black;
    text-decoration: none;
}

.liens {
    display: flex;
    flex-direction: column;
    min-height: 20vh;
    border-top: solid 1px;
}

@media screen and (min-width: 768px) {
    .liens {
        flex-direction: row;
    }
}

.adresse {
    display: flex;
}

@media screen and (min-width: 768px) {
    .adresse {
        width: 50vw;
    }
    .comptes {
        width: 50vw;
    }
}

.detailsAdresse {
    margin: auto;
}

.pAdresse {
    margin: 0;
}

.comptes {
    display: flex;
}

.detailsComptes {
    margin: auto;
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.facebook {
    width: 20px;
    background: rgb(66, 103, 178);
}

.instagram {
    width: 20px;
}

.linkedin {
    width: 20px;
    background: rgb(40, 103, 178);
}

@media screen and (min-width: 768px) {
    .facebook {
        width: 50px;
    }
    .instagram {
        width: 50px;
    }
    .linkedin {
        width: 50px;
    }
}